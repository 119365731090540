import {
  Card,
  ECardCarouselSize,
  ECardMediaSize,
  EIconButtonSize,
  EIconButtonVariant,
  ETagVariant,
  IconButton,
  Loader,
  Tag,
} from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Swiper } from 'swiper';

import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { trackEvent } from '@/services/track-event';
import { isProduction } from '@/utility/isSSR';
import { isCampgroundTile, ISearchResultTile } from '@/utility/mapSearchResultToTile';

type TListingTileMediaProps = {
  rentalTile: ISearchResultTile;
  isOutlined?: boolean;
  showFirstImageOnly?: boolean;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  onSlideChange?: (nextIndex: number) => void;
  isHorizontal?: boolean;
  isHorizontalBorderless?: boolean;
  showLimitedOfferTag?: boolean;
  showGuestFavorite?: boolean;
  withPriority?: boolean;
  withFetchPriority?: 'high' | 'low' | undefined;
  isNewHomepage?: boolean;
};

export const ListingTileMedia = ({
  rentalTile,
  isOutlined,
  showFirstImageOnly,
  addFavorite,
  removeFavorite,
  onSlideChange,
  isHorizontal = false,
  isHorizontalBorderless = false,
  showLimitedOfferTag,
  showGuestFavorite = false,
  withPriority = false,
  withFetchPriority,
  isNewHomepage = false,
}: TListingTileMediaProps) => {
  const intl = useIntl();
  const { isAboveXXXL } = useBreakpoint();
  const [swiperInstance, setSwiperInstance] = useState<Swiper>();

  const { id = 0, loading, photoUrl, isFavorite, photoAlt } = rentalTile;

  const listingLoader = !!loading && (
    <Loader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
  );

  const toggleFavorite =
    !!addFavorite &&
    !!removeFavorite &&
    (() => {
      (isFavorite && removeFavorite(id)) || addFavorite(id);

      trackEvent({
        event: 'rv-show/click',
        action: 'save',
        rentalId: id,
      });
    });

  const guestFavoriteTag = useMemo(() => {
    if (isCampgroundTile(rentalTile)) {
      return null;
    }

    return rentalTile.details?.tags?.find(rentalTag => rentalTag.slug === 'guest-favorite');
  }, [rentalTile]);

  const tag = useMemo(() => {
    if (isCampgroundTile(rentalTile)) {
      return null;
    }

    if (showLimitedOfferTag) {
      return (
        <Tag
          variant={ETagVariant.Success}
          label={intl.formatMessage({ defaultMessage: 'Limited Offer', id: 'bDCVSI' })}
          className="uppercase"
        />
      );
    }

    if (showGuestFavorite && guestFavoriteTag) {
      return <Tag variant={ETagVariant.White} label={guestFavoriteTag.tag} className="uppercase" />;
    }

    return null;
  }, [guestFavoriteTag, intl, rentalTile, showGuestFavorite, showLimitedOfferTag]);

  const favoriteToggleButton = !!toggleFavorite && (
    <IconButton
      aria-label={
        isFavorite
          ? intl.formatMessage({ defaultMessage: 'Saved', id: 'fsB/4p' })
          : intl.formatMessage({ defaultMessage: 'Click to save', id: 'q+y0s3' })
      }
      icon={isFavorite ? 'General.Heart.Filled' : 'General.Heart.Unfilled'}
      size={EIconButtonSize.Small}
      variant={EIconButtonVariant.Primary}
      onClick={toggleFavorite}
      onClickCapture={(e: React.MouseEvent) => {
        e.preventDefault();
      }}
      className={clsx({
        'text-red-600': isFavorite && !isNewHomepage,
        'text-black': isFavorite && isNewHomepage,
      })}
    />
  );

  const actionsLayer = (
    <div
      className={clsx('absolute top-0 left-0 flex w-full p-2 gap-2 items-start', {
        'justify-between': !!showGuestFavorite,
        '!justify-end': !showGuestFavorite || !guestFavoriteTag,
      })}>
      {tag} {favoriteToggleButton}
    </div>
  );

  useEffect(() => {
    if (!swiperInstance) return;

    const handleRealIndexChange = (swiper: Swiper) => {
      onSlideChange?.(swiper.realIndex);
    };

    swiperInstance.on('realIndexChange', handleRealIndexChange);

    return () => {
      swiperInstance.off('realIndexChange', handleRealIndexChange);
    };
  }, [swiperInstance, onSlideChange]);

  if (!loading && !showFirstImageOnly && Array.isArray(photoUrl) && photoUrl.length > 1) {
    return (
      <Card.Carousel
        ref={ref => setSwiperInstance(ref?.swiper)}
        // some images in dev have the same url?
        content={
          // Show a maximum of 7 images
          photoUrl.slice(0, 7).map((src, index) => (
            <MediaImage
              key={`${!isProduction() ? index : 'image'}-${src}`}
              src={src}
              isLarge={isAboveXXXL}
              alt={index === 0 ? photoAlt : undefined}
              withPriority={index === 0 && withPriority}
              withFetchPriority={index === 0 ? withFetchPriority : undefined}
            />
          ))
        }
        size={isAboveXXXL ? ECardCarouselSize.Large : ECardCarouselSize.Medium}
        fullRadius
        className={clsx({
          '!h-[214px]': isHorizontal,
          '!h-[140px] aspect-square': isHorizontalBorderless,
          'outline outline-2 outline-offset-2 outline-green-500': isOutlined,
          'aspect-[407/236] !h-full [&>div]:!pb-0': isNewHomepage,
        })}>
        {listingLoader}
        {actionsLayer}
      </Card.Carousel>
    );
  }

  return (
    <Card.Media
      size={isAboveXXXL ? ECardMediaSize.Large : ECardMediaSize.Medium}
      fullRadius
      className={clsx('bg-gray-200', {
        '!h-[214px]': isHorizontal,
        '!h-[140px] aspect-square': isHorizontalBorderless,
        'outline outline-2 outline-offset-2 outline-green-500': isOutlined,
        'aspect-[407/236] !h-full': isNewHomepage,
      })}>
      {listingLoader}
      {typeof photoUrl === 'string' && (
        <MediaImage
          src={photoUrl}
          alt={photoAlt}
          isLarge={isAboveXXXL}
          withPriority={withPriority}
          withFetchPriority={withFetchPriority}
        />
      )}
      {Array.isArray(photoUrl) && !!photoUrl[0] && (
        <MediaImage
          src={photoUrl[0]}
          alt={photoAlt}
          isLarge={isAboveXXXL}
          withPriority={withPriority}
          withFetchPriority={withFetchPriority}
        />
      )}
      {actionsLayer}
    </Card.Media>
  );
};

const MediaImage = ({
  src,
  isLarge,
  alt,
  withPriority = false,
  withFetchPriority,
}: {
  src: string;
  isLarge: boolean;
  alt?: string;
  withPriority?: boolean;
  withFetchPriority?: 'high' | 'low' | undefined;
}) => {
  return (
    <ResponsiveImage
      src={src}
      alt={alt}
      cropMode="fill"
      role="presentation"
      sourceSizes={isLarge ? ['landscape572'] : ['landscape375']}
      className="object-cover object-center w-full h-full"
      priority={withPriority}
      withFetchPriority={withFetchPriority}
    />
  );
};
