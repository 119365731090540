import { Card, ETextStyleVariant, SkeletonLine, Text } from '@outdoorsyco/bonfire';
import React from 'react';

import { ProBadge } from '@/components/common/ProBadge';
import { trackFeatureViewedEvent } from '@/services/analytics/features';
import { EFeatureName, EFeatureSource } from '@/services/analytics/features/types';
import { isCampgroundTile, ISearchResultTile } from '@/utility/mapSearchResultToTile';

import { CampgroundPrice } from './CampgroundPrice';
import { PriceDetails } from './PriceDetails';
import { RentalInfo } from './RentalInfo';
import { RentalPrice } from './RentalPrice';
import { RentalRating } from './RentalRating';

type TListingTileContentProps = {
  rentalTile: ISearchResultTile;
  customTitle?: string;
  showProBadge?: boolean;
};

export const ListingTileContent = ({
  rentalTile,
  customTitle,
  showProBadge,
}: TListingTileContentProps) => {
  const isCampground = isCampgroundTile(rentalTile);

  const handleTooltipClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const priceDetails =
    rentalTile.price &&
    !isCampground &&
    (rentalTile.isCampsite ? (
      <RentalPrice currency={rentalTile.currency} {...rentalTile.price} isCampsite />
    ) : (
      <PriceDetails showDiscount {...rentalTile.price} />
    ));

  const titleComponent = (
    <Text component="span" variant={ETextStyleVariant.MediumBold} className="line-clamp-2">
      {showProBadge && (
        // eslint-disable-next-line
        <span onClick={handleTooltipClick} className="align-middle">
          <ProBadge
            onMouseEnter={e => {
              const card = e.currentTarget.closest('[title]');
              if (card) {
                // Temporarily remove the title to prevent the browser's default tooltip
                card.setAttribute('data-original-title', card.getAttribute('title') ?? '');
                card.removeAttribute('title');
              }
              trackFeatureViewedEvent({
                featureName: EFeatureName.PRO_BADGE,
                rentalID: rentalTile.id,
                source: EFeatureSource.LISTING_TILE,
              });
            }}
            onMouseLeave={e => {
              const card = e.currentTarget.closest('[data-original-title]');
              if (card) {
                card.setAttribute('title', card.getAttribute('data-original-title') ?? '');
                card.removeAttribute('data-original-title');
              }
            }}
            width={16}
            height={16}
            className="inline-block mr-2"
          />
        </span>
      )}

      {(customTitle ? customTitle : rentalTile.title) ??
        (rentalTile.loading && <SkeletonLine className="bg-gray-200" />)}
    </Text>
  );

  return (
    <Card.Content className="w-full py-2">
      {titleComponent}

      <RentalInfo
        isCampground={isCampground}
        campgroundSubtitle={(isCampground && rentalTile.sitesDisplayText) || ''}
        loading={rentalTile.loading}
        isStay={isCampground ? false : rentalTile.isStay}
        title={rentalTile.title}
        {...rentalTile.details}
        hideDetails={!!customTitle}
      />

      <div className="flex items-center justify-between my-1 gap-2">
        {rentalTile.price ? (
          isCampground ? (
            <CampgroundPrice currency={rentalTile.currency} {...rentalTile.price} />
          ) : (
            priceDetails
          )
        ) : (
          rentalTile.loading && (
            <Text component="span" variant={ETextStyleVariant.LargeBold} className="grow">
              <SkeletonLine className="bg-gray-200" />
            </Text>
          )
        )}

        {!!rentalTile.details?.ratingNumber && (
          <RentalRating
            rating={rentalTile.details.ratingNumber}
            quantity={rentalTile.details.ratingQtd}
          />
        )}
      </div>
    </Card.Content>
  );
};
