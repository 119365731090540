import { Icon, Tooltip } from '@outdoorsyco/bonfire';
import { useIntl } from 'react-intl';

interface IProBadgeProps {
  icon?: 'General.Storefront.Simple' | 'General.Storefront.Detailed';
  className?: string;
  width?: number;
  height?: number;
  onMouseEnter?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export const ProBadge = ({
  icon = 'General.Storefront.Simple',
  className,
  width = 24,
  height = 24,
  onMouseEnter,
  onMouseLeave,
}: IProBadgeProps) => {
  const intl = useIntl();
  return (
    <Tooltip
      content={intl.formatMessage({
        defaultMessage:
          'This host operates as a business, ensuring a reliable and smooth booking experience.',
        id: 'Kdx/I5',
      })}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Icon name={icon} height={height} width={width} />
    </Tooltip>
  );
};
