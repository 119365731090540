import { Card, ETextStyleVariant, SkeletonLine, Text } from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import React from 'react';

import { isCampgroundTile, ISearchResultTile } from '@/utility/mapSearchResultToTile';

import { CampgroundPrice } from './CampgroundPrice';
import { PriceDetails } from './PriceDetails';
import { RentalInfo } from './RentalInfo';
import { RentalPrice } from './RentalPrice';
import { RentalRating } from './RentalRating';

type THorizontalListingTileContentProps = {
  rentalTile: ISearchResultTile;
  customTitle?: string;
};

export const HorizontalListingTileContent = ({
  rentalTile,
  customTitle,
}: THorizontalListingTileContentProps) => {
  const isCampground = isCampgroundTile(rentalTile);

  const priceDetails =
    rentalTile.price &&
    !isCampground &&
    (rentalTile.isCampsite ? (
      <RentalPrice
        currency={rentalTile.currency}
        {...rentalTile.price}
        isCampsite
        textClassName="text-xl"
      />
    ) : (
      <PriceDetails showDiscount {...rentalTile.price} />
    ));

  return (
    <Card.Content className="w-3/5 py-4 pl-4 pr-3">
      <div className="flex flex-col justify-between h-full">
        <div>
          <Text
            component="span"
            variant={ETextStyleVariant.LargeBold}
            className="text-gray-800 line-clamp-2">
            {(customTitle ? customTitle : rentalTile.title) ??
              (rentalTile.loading && <SkeletonLine className="bg-gray-200" />)}
          </Text>

          <RentalInfo
            isCampground={isCampground}
            campgroundSubtitle={(isCampground && rentalTile.sitesDisplayText) || ''}
            loading={rentalTile.loading}
            isStay={isCampground ? false : rentalTile.isStay}
            title={rentalTile.title}
            {...rentalTile.details}
            hideDetails={!!customTitle}
          />
        </div>

        <div className={clsx('flex items-center justify-between my-1 gap-2 w-full')}>
          {rentalTile.price ? (
            isCampground ? (
              <CampgroundPrice currency={rentalTile.currency} {...rentalTile.price} />
            ) : (
              priceDetails
            )
          ) : (
            rentalTile.loading && (
              <Text component="span" variant={ETextStyleVariant.LargeBold} className="grow">
                <SkeletonLine className="bg-gray-200" />
              </Text>
            )
          )}

          {!!rentalTile.details?.ratingNumber && (
            <RentalRating
              rating={rentalTile.details.ratingNumber}
              quantity={rentalTile.details.ratingQtd}
            />
          )}
        </div>
      </div>
    </Card.Content>
  );
};
