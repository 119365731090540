import { EProjectSection, trackEventV2 } from '../trackEventV2';
import { EFeatureEventName, IProBadgeHoveredEvent } from './types';

export const trackFeatureViewedEvent = (event: IProBadgeHoveredEvent) => {
  trackEventV2(EFeatureEventName.FEATURE_VIEWED, EProjectSection.MARKETPLACE, {
    featureName: event.featureName,
    source: event.source,
    rentalID: event.rentalID,
  });
};
