export enum EFeatureEventName {
  FEATURE_VIEWED = 'Feature Viewed',
}

export enum EFeatureSource {
  LISTING_TILE = 'Listing Tile',
  LISTING_HEADER = 'Listing Header',
}

export enum EFeatureName {
  PRO_BADGE = 'Pro Badge',
}

export interface IProBadgeHoveredEvent {
  featureName: EFeatureName;
  source: EFeatureSource;
  rentalID?: number;
}
